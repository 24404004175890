import React, {useEffect, useRef} from 'react';
import "./index.scss"
import BannerPic1 from '../../assets/images/banner1.png'
import BannerPic2 from '../../assets/images/banner2.png'
import BannerPic3 from '../../assets/images/banner3.png'
import BannerPic4 from '../../assets/images/banner4.png'
import BannerPic5 from '../../assets/images/banner5.png'
import BannerPic6 from '../../assets/images/banner6.png'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
const ProjectBanner = () => {

    useEffect(() => {
        const sections = gsap.utils.toArray('.scrollSec');

        const context = gsap.context(() => {
            sections.forEach((section, index) => {
                const scrollDirection = index === 1 ? 1 : -1;

                gsap.fromTo(
                    section,
                    { y: scrollDirection * 200 },
                    {
                        y: scrollDirection * -200,
                        scrollTrigger: {
                            trigger: section,
                            start: 'top bottom',
                            end: 'bottom top',
                            scrub: 4,
                        }
                    }
                );
            });
        }, ...sections); // Scope the context to all sections

        return () => context.revert(); // Cleanup on unmount
    }, []);


    return (
        <div className="mainBanner">
            <div className="scroller">
                <div className="scrollSec">
                    <img src={BannerPic1} alt="banner_1"/>
                    <img src={BannerPic3} alt="banner_3"/>
                    <img src={BannerPic4} alt="banner_4"/>
                    <img src={BannerPic2} alt="banner_4"/>
                </div>
                <div className="scrollSec">
                    <img src={BannerPic5} alt="banner_1"/>
                    <img src={BannerPic6} alt="banner_6"/>
                    <img src={BannerPic1} alt="banner_1"/>
                    <img src={BannerPic3} alt="banner_3"/>
                </div>
                <div className="scrollSec">
                    <img src={BannerPic2} alt="banner_2"/>
                    <img src={BannerPic5} alt="banner_5"/>
                    <img src={BannerPic4} alt="banner_4"/>
                    <img src={BannerPic6} alt="banner_6"/>
                </div>
            </div>
        </div>

    );
};

export default ProjectBanner;