import React, {useState} from 'react';
import "./index.scss";
import CustomInput from "../CustomInput";
import SendLogo from "../../assets/logos/ContactSendWhite.png";
import Maps from "../../assets/images/maps.png";

const Contact = () => {
    const [companyNameValue, setCompanyNameValue] = useState('');
    const [firstNameValue, setFirstNameValue] = useState('');
    const [lastNameValue, setLastNameValue] = useState('');
    const [mobileValue, setMobileValue] = useState('');
    const [addressValue, setAddressValue] = useState('');
    const [emailValue, setEmailValue] = useState('');

    const handleCompanyNameChange = (newValue) => {
        setCompanyNameValue(newValue);
    };
    const handleFirstNameChange = (newValue) => {
        setFirstNameValue(newValue);
    };
    const handleLastNameChange = (newValue) => {
        setLastNameValue(newValue);
    };
    const handleMobileChange = (newValue) => {
        setMobileValue(newValue);
    };
    const handleAddressChange = (newValue) => {
        setAddressValue(newValue);
    };
    const handleEmailChange = (newValue) => {
        setEmailValue(newValue);
    };
    return (
        <div className="contact_container">
            <div className="contact_title">
                <p>A jeni regjistruar ende?</p>
                <h2>Krijoni llogarinë biznesore tani</h2>
            </div>

            <div className="contact_section" id="kontakti">
                <div className="first_contact_sec">
                    <p>Ju lutemi caktoni lokacionin e biznesit tuaj ne hartën më poshtë:</p>
                    <img src={Maps} alt="map_logo"/>

                    <div className="contact_rules">
                        <p>Për të hapur llogari biznesore duhet:</p>
                        <li>Të keni një llogari aktive në aplikacionin e berberit</li>
                        <li>Të keni verifikuar numrin në llogarinë tuaj</li>
                    </div>
                </div>

                <div className="second_contact_sec">
                    <p className="second_contact_sec_title">Ju lutemi shkruani të dhënat tuaja</p>
                    <CustomInput title={"Emri i biznesit"} type={"text"} value={companyNameValue} onChange={handleCompanyNameChange}/>
                    <div className="owner_name">
                        <CustomInput title={"Emri i pronarit"} type={"text"} value={firstNameValue} onChange={handleFirstNameChange}/>
                        <CustomInput title={"Mbiemri i pronarit"} type={"text"} value={lastNameValue} onChange={handleLastNameChange}/>
                    </div>
                    <CustomInput title={"Numri i telefonit"} type={"tel"} value={mobileValue} onChange={handleMobileChange}/>
                    <CustomInput title={"Emri i rrugës dhe qytetit"} type={"text"} value={addressValue} onChange={handleAddressChange}/>
                    <CustomInput title={"Email"} type={"mail"} value={emailValue} onChange={handleEmailChange}/>
                    {/*TODO mesazhi per success ose fail*/}
                    <p className="contact_error_message" style={{display: "none"}}>Kërkesa juaj nuk u dërgua me sukses, ju lutemi provoni përsëri më vonë !</p>
                    <div className="contact_send_request">
                        <img src={SendLogo} alt="send_logo"/>
                        <p>Dërgo Tani</p>
                    </div>
                </div>

                <div className="contact_rules_2">
                    <p>Për të hapur llogari biznesore duhet:</p>
                    <li>Të keni një llogari aktive në aplikacionin e berberit</li>
                    <li>Të keni verifikuar numrin në llogarinë tuaj</li>
                </div>
            </div>
        </div>
    );
};

export default Contact;