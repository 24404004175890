import React, {useContext, useEffect, useRef} from 'react';
import gsap from 'gsap';
import ScrollTrigger4 from 'gsap/ScrollTrigger';
import './index.scss';
import AnimationContext from "../../contexts/AnimationContext";

gsap.registerPlugin(ScrollTrigger4);

const SplitedText = () => {
    const wrapperRef = useRef(null);
    const changeBackground = useRef(null);

    const {currentBg, setCurrentBg} = useContext(AnimationContext);

    useEffect(() => {
        gsap.timeline({
            scrollTrigger:{
                trigger: changeBackground.current,
                start: "+=200 70%",
                end: "+=200 60%",
                scrub: false,
                pinSpacing: false,
                onEnter: () => {
                    setCurrentBg('#42295e');
                    gsap.to(wrapperRef.current, {
                        color: "#ffffff",
                        duration: .5,
                    })
                },
                onLeaveBack: () => {
                    setCurrentBg('#FFFFFF');
                    gsap.to(wrapperRef.current, {
                        color: "#000",
                        duration: .5,
                    })
                }
            }
        })
    }, []);

    useEffect(() => {
        const wrapper = wrapperRef.current;
        const context1 = gsap.context(() => {
            gsap.fromTo(
                wrapper,
                { x: -1000 },
                {
                    x: 200,
                    scrollTrigger: {
                        trigger: wrapper,
                        start: 'top bottom',
                        end: 'bottom top',
                        scrub: 2
                    }
                }
            );
        }, wrapperRef); // Scope the context to the specific wrapper

        return () => context1.revert(); // Cleanup on unmount
    }, []);

    return (
        <div className='demo-text' ref={changeBackground}>
            <div className='wrapper text' ref={wrapperRef}>
                BERBERI&bull;BERBERI&bull;BERBERI&bull;BERBERI&bull;BERBERI&bull;BERBERI&bull;BERBERI
            </div>
        </div>
    );
};

export default SplitedText;
