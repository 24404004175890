import React, {useEffect, useRef} from "react";
import "./index.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Panel  from "../Panel";

gsap.registerPlugin(ScrollTrigger);
const Instructions = () => {
    const panelsRef = useRef([]);

    useEffect(() => {
        const panels = gsap.utils.toArray(panelsRef.current);
        const lastPanelIndex = panels.length - 1;

        // Create ScrollTrigger for each panel to handle pinning
        panels.forEach((panel, i) => {
            ScrollTrigger.create({
                trigger: panel,
                start: "top top",
                end: i === lastPanelIndex ? "bottom bottom" : "bottom top", // Adjust end for last panel
                pin: i !== lastPanelIndex, // Pin all except the last panel
                pinSpacing: false,
                scrub: true
            });
        });

        // Ensure the last panel is not pinned and allows normal scrolling
        ScrollTrigger.create({
            trigger: panels[lastPanelIndex],
            start: "top bottom",
            end: "bottom top",
            pin: false,
            scrub: true
        });

        return () => {
            // Clean up ScrollTriggers on component unmount
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, []);

    return (
        <div className="instructions_container">
            <div className="panel" style={{background: "#6b449a"}} ref={el => (panelsRef.current[0] = el)}>
                <Panel
                    index={1}
                    title={"Regjistrohu si përdorues i ri"}
                    description={"Ju mundeni të bëheni përdorues i berberit duke plotësuar me të dhënat tuaja. Në fund shtypni butonin e konfirmimit për të vazhduar me hapat tjerë të procesit të regjistrimit !"}
                />
            </div>
            <div className="panel" style={{background: "#5d3b86"}} ref={el => (panelsRef.current[1] = el)}>
                <Panel
                    index={2}
                    title={"Verifikoni numrin e telefonit"}
                    description={"Pasi të keni vazhduar regjistrimin, ju duhet të verifikoni numrin tuaj. Kodin e verifikimit do ta merrni përmes SMS i cili është dërguar në numrin që ju keni plotësuar në hapin e mëparshëm !"}
                />
            </div>
            <div className="panel" style={{background: "#4f3272"}} ref={el => (panelsRef.current[2] = el)}>
                <Panel
                    index={3}
                    title={"Hulumto berberët ekzistues"}
                    description={"Pasi të keni krijuar llogarinë tuaj, ju mund të hulumtoni të gjithë berberët ekzistues, ata me afër tuaj lokacionit aktual si dhe ata të cilët ju keni pëlqyer ose mund të pëlqeni dhe ruani për përdorim të mëvonshëm !"}
                />
            </div>
            <div className="panel" style={{background: "#42295E"}} ref={el => (panelsRef.current[3] = el)}>
                <Panel
                    index={4}
                    title={"Caktoni terminin te berberi i preferuar"}
                    description={"Nëse keni zgjedhur berberin tek i cili dëshironi të caktoni termin, ju mund të zgjedhni datën, orën si dhe antërin e ekipit tek i cili dëshironi të caktoni terminin tuaj. Dhe krejt në fund mos harroni të shtpni butonin e konfirmimit. Në rastin që termini juaj aprovohet ju do të njoftoheni menjeherë ! "}
                />
            </div>

            <div className="panel" style={{background: "#42295E"}} ref={el => (panelsRef.current[4] = el)}></div>
        </div>
    );
};

export default Instructions;
