import React, {useState} from 'react';
import "./index.scss"
import LeftArrow from "../../assets/logos/leftArrow.png"
import CustomInput from "../../components/CustomInput";
import SendLogo from "../../assets/logos/ContactSendWhite.png";
import Support from "../../assets/logos/support.png";
import FooterAppstore from "../../assets/logos/FooterAppstore.png";
import FooterGooglePlay from "../../assets/logos/FooterGoogleplay.png";
import {useNavigate} from "react-router-dom";
import {ReactLenis} from "lenis/react";
import AnimatedCursor from "react-animated-cursor";

const ClientContact = () => {
    const [firstNameValue, setFirstNameValue] = useState('');
    const [lastNameValue, setLastNameValue] = useState('');
    const [subjectValue, setSubjectValue] = useState('');
    const [descValue, setDescValue] = useState('');
    const [emailValue, setEmailValue] = useState('');
    let navigate = useNavigate();
    const routeChange = () =>{
        let path = `/`;
        navigate(path);
    }
    const handleFirstNameChange = (newValue) => {
        setFirstNameValue(newValue);
    };
    const handleLastNameChange = (newValue) => {
        setLastNameValue(newValue);
    };
    const handleSubjectChange = (newValue) => {
        setSubjectValue(newValue);
    };
    const handleDescChange = (newValue) => {
        setDescValue(newValue);
    };
    const handleEmailChange = (newValue) => {
        setEmailValue(newValue);
    };
    return (
        <ReactLenis root>
            <AnimatedCursor
                innerStyle={{zIndex: 100000000000000}}
                innerSize={15}
                outerSize={15}
                color='168, 92, 255'
                outerAlpha={0.2}
                innerScale={0.7}
                outerScale={5}
            />
            <div className="client_contact_container">
                <div className="client_contact_header">
                    <div className="go_back_btn" onClick={routeChange}>
                        <img src={LeftArrow} alt="left_arrow"/>
                        <p>Kthehu tek faqja kryesore</p>
                    </div>
                    <div className="support-contact">
                        <img src={Support} alt="support"/>
                        <p>049534634 - 049897334</p>
                    </div>
                </div>

                <div className="client_contact_title">
                    <h3>Është kënaqësi të dëgjojmë mendimet tuaja</h3>
                    <p>Informohu dhe jepni idenë tuaj</p>
                </div>
                <div className="client_contact_form">
                    <div className="client_name">
                        <CustomInput title={"Emri i juaj"} type={"text"} value={firstNameValue}
                                     onChange={handleFirstNameChange} isForContactPage={true}/>
                        <CustomInput title={"Mbiemri i juaj"} type={"text"} value={lastNameValue}
                                     onChange={handleLastNameChange} isForContactPage={true}/>
                    </div>
                    <CustomInput title={"Email"} type={"mail"} value={emailValue} onChange={handleEmailChange}
                                 isForContactPage={true}/>
                    <CustomInput title={"Titulli"} type={"mail"} value={subjectValue} onChange={handleSubjectChange}
                                 isForContactPage={true}/>
                    <textarea className="client_conatct_description" onChange={handleDescChange} name="description_name"
                              rows="15" placeholder={"Mesazhi juaj..."} value={descValue}></textarea>
                    {/*TODO mesazhi per success ose fail*/}
                    <p className="client_contact_error_message" style={{display: "none"}}>Kërkesa juaj nuk u dërgua me
                        sukses, ju lutemi provoni përsëri më vonë !</p>
                    <div className="client_contact_send_request">
                        <img src={SendLogo} alt="send_logo"/>
                        <p>Dërgo Tani</p>
                    </div>

                </div>
                <p className="client_btns_title">Shkarkoni aplikacionin në platformat</p>
                <div className="client_btns">
                    <div className="client_ios">
                        <img src={FooterAppstore} alt="footer_appstore"/>
                        <a href="#">App Store</a>
                    </div>
                    <div className="client_android">
                        <img src={FooterGooglePlay} alt="footer_playstore"/>
                        <a href="#">Play Store</a>
                    </div>
                </div>

                <div className="support-contact_footer">
                    <img src={Support} alt="support"/>
                    <p>049534634 - 049897334</p>
                </div>
            </div>

        </ReactLenis>

    );
};

export default ClientContact;