import React from 'react';
import "./index.scss"
import {ReactLenis} from "lenis/react";
import LeftArrow from "../../assets/logos/leftArrow.png";
import {useNavigate} from "react-router-dom";
import AnimatedCursor from "react-animated-cursor";

const TermsAndConditions = () => {

    let navigate = useNavigate();
    const routeChange = () =>{
        let path = `/`;
        navigate(path);
    }
    return (
        <ReactLenis root>
            <AnimatedCursor
                innerStyle={{zIndex: 100000000000000}}
                innerSize={15}
                outerSize={15}
                color='168, 92, 255'
                outerAlpha={0.2}
                innerScale={0.7}
                outerScale={5}
            />
            <div className='terms_container'>
                <div className="go_back_terms" onClick={routeChange}>
                    <img src={LeftArrow} alt="left_arrow"/>
                    <p>Kthehu tek faqja kryesore</p>
                </div>
                <section>
                    <h1 className="terms_policy">Terms of Service</h1>
                    <p className="terms_updated"><strong>Last Updated: 2024-09-05</strong></p>
                    <p className="terms_welcome">Welcome to <strong>Berberi</strong>! These Terms of Service ("Terms")
                        govern your use of our mobile
                        application. By accessing or using the app, you agree to be bound by these Terms. Please read
                        them
                        carefully.</p>

                    <h2>1. Acceptance of Terms</h2>
                    <p className="terms_padding">By creating an account or otherwise using the app, you acknowledge that
                        you have read, understood,
                        and agree to comply with these Terms. If you do not agree to these Terms, you must not use our
                        services.</p>

                    <h2>2. User Eligibility and Responsibilities</h2>
                    <p className="terms_padding">To use this app, you must be at least 14 years old. You agree to
                        provide accurate and complete
                        information during the registration process and update it as necessary to keep it current. You
                        are
                        solely responsible for maintaining the confidentiality of your login credentials and all
                        activities
                        that occur under your account.</p>

                    <h2>3. App Usage</h2>
                    <p className="terms_padding">Berberi allows users to book appointments with nearby barbershops based
                        on their location. Users must
                        ensure they provide accurate information while booking appointments. We do not guarantee the
                        availability of specific barbershops or services and are not responsible for any cancellations
                        or
                        rescheduling.</p>

                    <h2>4. Location Services</h2>
                    <p className="terms_padding">To provide location-based services, Berberi may request access to your
                        location. This information is
                        used solely to show you the closest barber shops and is not stored or retained by us.</p>

                    <h2>5. Intellectual Property</h2>
                    <p className="terms_padding">All content, design, and functionality within the app, including but
                        not limited to text, graphics,
                        logos, and software, are the exclusive property of Berberi or its licensors. You may not
                        reproduce,
                        modify, distribute, or otherwise use any of the app content without prior written consent from
                        us.</p>

                    <h2>6. Termination</h2>
                    <p className="terms_padding">We reserve the right to suspend or terminate your account and access to
                        the app at our discretion,
                        without prior notice, for any reason, including but not limited to a violation of these
                        Terms.</p>

                    <h2>7. Limitation of Liability</h2>
                    <p className="terms_padding">To the fullest extent permitted by law, Berberi shall not be liable for
                        any indirect, incidental, or
                        consequential damages arising from your use of the app. This includes but is not limited to loss
                        of
                        data, personal injury, or any other damages.</p>

                    <h2>8. Changes to the Terms</h2>
                    <p className="terms_padding">We reserve the right to modify these Terms at any time. Changes will be
                        effective immediately upon
                        posting to the app. Your continued use of the app after changes indicates your acceptance of the
                        new
                        Terms.</p>

                    <h2>9. Governing Law</h2>
                    <p className="terms_padding">These Terms shall be governed by and construed in accordance with the
                        laws of Republic of Kosovo. Any
                        legal disputes arising out of or in connection with these Terms will be resolved in the courts
                        of
                        Republic of Kosovo.</p>

                    <h2>10. Contact Us</h2>
                    <p className="terms_padding">If you have any questions about these Terms, please contact us at
                        info@berberi.app</p>
                </section>
                <section>
                    <h1 className="terms_policy">Privacy Policy</h1>
                    <p className="terms_updated"><strong>Last Updated: 2024-09-05</strong></p>
                    <p className="terms_welcome">At <strong>Berberi</strong>, we are committed to protecting your
                        privacy. This Privacy Policy
                        outlines how we collect, use, and protect your information.</p>

                    <h2>1. Information We Collect</h2>
                    <p className="terms_padding">We collect the following information to provide our services:</p>
                    <ul className="terms_padding2">
                        <li><strong>Personal Information:</strong> Full name, phone number, email address, and password
                            for
                            account creation and management.
                        </li>
                        <li><strong>Location Data:</strong> Access to your location to show you the closest barbershops.
                            This data is used in real-time and is not stored or retained by us.
                        </li>
                    </ul>

                    <h2>2. How We Use Your Information</h2>
                    <p className="terms_padding">Your information is used for the following purposes:</p>
                    <ul className="terms_padding2">
                        <li>To create and manage your account.</li>
                        <li>To send appointment confirmations and updates via email or SMS.</li>
                        <li>To provide location-based services such as showing nearby barbershops.</li>
                        <li>To communicate with you and provide customer support.</li>
                    </ul>

                    <h2>3. How We Protect Your Information</h2>
                    <p className="terms_padding">We implement industry-standard security measures to protect your
                        personal information. Access to your
                        data is restricted to authorized personnel only, who are required to maintain its
                        confidentiality.
                        While we strive to use commercially acceptable means to protect your personal information, we
                        cannot
                        guarantee its absolute security.</p>

                    <h2>4. Sharing Your Information</h2>
                    <p className="terms_padding">We do not sell, rent, or share your personal information with third
                        parties, except as required by
                        law or to provide our services. In some cases, we may share information with trusted third-party
                        service providers who assist us in operating our app, but only to the extent necessary to
                        perform
                        their functions.</p>

                    <h2>5. Data Retention</h2>
                    <p className="terms_padding">We retain your personal information for as long as your account is
                        active or as necessary to provide
                        our services. You may request the deletion of your information at any time by contacting us
                        at <strong>info@berberi.app</strong>. We will respond to your request promptly, but please note
                        that
                        certain information may be retained to comply with legal obligations or resolve disputes.</p>

                    <h2>6. Your Rights</h2>
                    <p className="terms_padding">You have the right to access, update, or delete your personal
                        information at any time. You also have
                        the right to withdraw your consent for us to use your information. If you have any questions or
                        concerns regarding your privacy rights, please contact us at <strong>info@berberi.app</strong>.
                    </p>

                    <h2>7. Changes to This Privacy Policy</h2>
                    <p className="terms_padding">We may update this Privacy Policy from time to time. Any changes will
                        be posted on this page, and we
                        will notify you of any significant changes. Your continued use of the app after changes
                        constitutes
                        your acceptance of the new policy.</p>

                    <h2>8. Third-Party Links</h2>
                    <p className="terms_padding">Our app may contain links to third-party websites or services that are
                        not operated by us. We have no
                        control over and assume no responsibility for the content, privacy policies, or practices of any
                        third-party sites or services. We encourage you to review the privacy policies of any
                        third-party
                        websites you visit.</p>

                    <h2>9. Contact Us</h2>
                    <p className="terms_padding">If you have any questions or concerns about this Privacy Policy, please
                        contact us
                        at <strong>info@berberi.app</strong>.</p>
                </section>
            </div>
        </ReactLenis>
    );
};

export default TermsAndConditions;