import React from 'react';
import "./index.scss"
import Slider from 'react-infinite-logo-slider'
import PlayStore from '../../assets/logos/playstore.png'
import AppStore from '../../assets/logos/appstore.png'
import Facebook from '../../assets/logos/facebook.png'
import Instagram from '../../assets/logos/instagram.png'
import Twitter from '../../assets/logos/twitter.png'
import BarberLogo from '../../assets/logos/barberLogo.png'
import ClientLogo from '../../assets/logos/client.png'

const HomeBarber = () => {
    return (
        <div className="bannerMain">
            <div className="home" style={{display: "flex", flexDirection: "row", alignItems: 'center'}}>
                <div className="two">
                    <p className="titleP">S'po mundesh me u qeth pa rezervim ?</p>
                    <h1>Rezevo termin më <span>shpejtë</span> se kurrë</h1>
                    <p className="desc">Aplikacioni i berberit ka për qëllim të revolucionarizojë menaxhimin e rezervimeve online. Rezervoni me lehtësi, përshtatni oraret dhe mbani gjithçka nën kontroll.
                        Shkarkoni tani dhe përjetoni një përvojë të re dhe të thjeshtë në menaxhimin e rezervimeve!</p>
                    <div className="buttons">
                        <a href="#">Shkarko Aplikacionin</a>
                        <a href="#projekti">Më shumë</a>
                    </div>
                </div>
            </div>
            <div className="logo_slider">
                <Slider
                    width="250px"
                    duration={20}
                    pauseOnHover={true}
                    blurBorders={true}
                    blurBoderColor={'#fdfdfd'}

                >
                    <Slider.Slide>
                        <img style={{opacity:.5, marginRight: "10px"}} src={PlayStore} alt="playstore"/>
                        <p style={{opacity:.3, fontSize:"20px"}}>PlayStore</p>
                    </Slider.Slide>
                    <Slider.Slide>
                        <img style={{opacity: .5, marginRight: "10px"}} src={AppStore} alt="applestore"/>
                        <p style={{opacity: .3, fontSize: "20px"}}>App Store</p>
                    </Slider.Slide>
                    <Slider.Slide>
                        <img style={{opacity: .5, marginRight: "10px"}} src={Facebook} alt="facebook"/>
                        <p style={{opacity: .3, fontSize: "20px"}}>Facebook</p>
                    </Slider.Slide>
                    <Slider.Slide>
                        <img style={{opacity: .5, marginRight: "10px"}} src={Instagram} alt="instagram"/>
                        <p style={{opacity: .3, fontSize: "20px"}}>Instagram</p>
                    </Slider.Slide>
                    <Slider.Slide>
                        <img style={{opacity: .5, marginRight: "10px"}} src={Twitter} alt="twitter"/>
                        <p style={{opacity: .3, fontSize: "20px"}}>Twitter</p>
                    </Slider.Slide>
                    <Slider.Slide>
                        <img style={{opacity: .5, marginRight: "10px"}} src={BarberLogo} alt="barberlogo"/>
                        <p style={{opacity: .3, fontSize: "20px"}}>Biznesi</p>
                    </Slider.Slide>
                    <Slider.Slide>
                        <img style={{opacity: .5, marginRight: "10px"}} src={ClientLogo} alt="clientlogo"/>
                        <p style={{opacity: .3, fontSize: "20px"}}>Klienti</p>
                    </Slider.Slide>
                </Slider>
            </div>
        </div>
    );
};

export default HomeBarber;