import React, {useContext, useEffect, useRef} from 'react';
import "./index.scss"
import AnimationContext from "../../contexts/AnimationContext";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import CardMonitoring from "../../assets/logos/CardMonitoring.png"
import CardUsers from "../../assets/logos/CardUsers.png"
import CardNotification from "../../assets/logos/CardNotification.png"
import CardFeed from "../../assets/logos/CardFeedback.png"
import CardFree from "../../assets/logos/CardFree.png"
import Waves from "../../assets/videos/dot-waves.gif"


gsap.registerPlugin(ScrollTrigger);

const Business = () => {

    const businessWrapperRef = useRef(null);
    const changeBackground = useRef(null);

    const {currentBg, setCurrentBg} = useContext(AnimationContext);

    useEffect(() => {
        gsap.timeline({
            scrollTrigger:{
                trigger: changeBackground.current,
                start: "+=100 70%",
                end: "+=200 60%",
                scrub: false,
                pinSpacing: false,
                onEnter: () => {
                    setCurrentBg('#FFFFFF');
                },
                onLeaveBack: () => {
                    setCurrentBg('#42295e');
                }
            }
        })
    }, []);
    return (
        <div className="business_container" ref={changeBackground}>
            <div className="business_spacer" id="sherbimet"></div>
            <div className="business_title" >
                <p >Tashmë jeni biznes ?</p>
                <h2>Ofrojmë zgjidhje për berberat</h2>
            </div>
            <div className="spline">
                <img src={Waves} alt="loading..."/>
            </div>
            <div className="business_cards" >
                <div className="business_card">
                    <div className=".business_card_icon"><img src={CardMonitoring} alt="card_monitoring"/></div>
                    <div className="business_card_text">
                        <p>Monitorim i të gjitha rezervimeve me mundësi filtrimi të të dhënave, si dhe menaxhimi të tyre !</p>
                    </div>
                </div>
                <div className="business_card">
                    <div className=".business_card_icon"><img src={CardUsers} alt="card_users"/></div>
                    <div className="business_card_text">
                        <p>Mundësi menaxhimi të stafit punues, si dhe mundësi të ndarjes së rezervimeve për secilin anëtar!</p>
                    </div>
                </div>
                <div className="business_card" ref={businessWrapperRef}>
                    <div className=".business_card_icon"><img src={CardNotification} alt="card_notifications"/></div>
                    <div className="business_card_text">
                        <p>Mundësi të marrjes së njoftimeve për çdo rezervim ose ndryshim të mundshëm</p>
                    </div>
                </div>
                <div className="business_card">
                    <div className=".business_card_icon"><img src={CardFeed} alt="card_feedback"/></div>
                    <div className="business_card_text">
                        <p>Mundësi të marrjes së vlerësimeve për të rritur përformancën dhe efikasitetin!</p>
                    </div>
                </div>
                <div className="business_card">
                    <div className=".business_card_icon"><img src={CardFree} alt="card_free"/></div>
                    <div className="business_card_text">
                        <p>Mundësi përdorimi të shërbimeve të applikacionit pa pagesë për periudhë 3 mujore!</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Business;