// AnimationContext.js
import React, { createContext, useState } from 'react';

const AnimationProvider = ({ children }) => {
    const [currentBg, setCurrentBg] = useState('#FFFFFF');

    return (
        <AnimationContext.Provider value={{ currentBg, setCurrentBg }}>
            {children}
        </AnimationContext.Provider>
    );
};

const AnimationContext = createContext(AnimationProvider);

export default AnimationContext;
