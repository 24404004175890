import React, {useContext, useEffect, useRef} from 'react';
import gsap from 'gsap';
import ScrollTrigger2 from 'gsap/ScrollTrigger';
import './index.scss';
import AnimationContext from "../../contexts/AnimationContext";

gsap.registerPlugin(ScrollTrigger2);

const SplitedText1 = () => {
    const wrapperRef1 = useRef(null);

    const changeBackground = useRef(null);

    const {currentBg, setCurrentBg} = useContext(AnimationContext);

    useEffect(() => {
        gsap.timeline({
            scrollTrigger:{
                trigger: changeBackground.current,
                start: "+=200 20%",
                end: "+=200 20%",
                scrub: true,
                pinSpacing: false,
                onEnter: () => {
                    gsap.to(wrapperRef1.current, {
                        color: "#ffffff",
                        duration: .5,
                    })
                },
                onLeaveBack: () => {
                    gsap.to(wrapperRef1.current, {
                        color: "#000",
                        duration: .5,
                    })
                }
            }
        })
    }, []);

    useEffect(() => {
        const wrapper1 = wrapperRef1.current;

        const context2 = gsap.context(() => {
            gsap.fromTo(
                wrapper1,
                { x: 100 },
                {
                    x: -1000,
                    scrollTrigger: {
                        trigger: wrapper1,
                        start: 'top bottom',
                        end: 'bottom top',
                        scrub: 2
                    }
                }
            );
        }, wrapperRef1); // Scope the context to the specific wrapper

        return () => context2.revert(); // Cleanup on unmount
    }, []);

    return (
        <section className='demo-text1'>
            <div className='wrapper1 text1' ref={wrapperRef1}>
                BERBERI&bull;BERBERI&bull;BERBERI&bull;BERBERI&bull;BERBERI&bull;BERBERI&bull;BERBERI
            </div>
            <div id="projekti"></div>
        </section>
    );
};

export default SplitedText1;
