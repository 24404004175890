import React, {useContext, useState} from 'react';
import "./index.scss"
import image from "../../assets/logos/ColorBarberLogo.png"
import HomeWhite from "../../assets/logos/HeaderHomeWhite.png"
import HomeDark from "../../assets/logos/HeaderHomeDark.png"
import ProjectWhite from "../../assets/logos/HeaderProjectWhite.png"
import ProjectDark from "../../assets/logos/HeaderProjectDark.png"
import ServiceWhite from "../../assets/logos/HeaderServiceWhite.png"
import ServiceDark from "../../assets/logos/HeaderServiceDark.png"
import ContactWhite from "../../assets/logos/HeaderContactWhite.png"
import ContactDark from "../../assets/logos/HeaderContactDark.png"
import AnimationContext from "../../contexts/AnimationContext";
import {Router, useNavigate} from "react-router-dom";


const Header = () => {
    const {currentBg, setCurrentBg} = useContext(AnimationContext);
    const [activeLink, setActiveLink] = useState(1);
    let navigate = useNavigate();
    const contactRoute = () =>{
        let path = `/contact`;
        navigate(path);
    }
    return (
        <div className="headerMain" style={{zIndex: 1000000}} id="ballina">
            <div className="elemSection" style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                zIndex: 1000000
            }}>
                <div className="logoSection" style={{zIndex: 1000000}}>
                    <img src={image} alt="berberi_logo" />
                </div>
                <div className="largeNavSection" style={{zIndex: 1000000}}>
                    <li><a href="#ballina">Ballina</a></li>
                    <li><a href="#projekti">Projekti</a></li>
                    <li><a href="#sherbimet">Shërbimet</a></li>
                    <li><a onClick={contactRoute}>Kontakti</a></li>
                </div>
                <div className="languageSection" style={{zIndex: 1000000}}>
                    <li className="content__item">
                        <a href="#kontakti" className="button button--calypso"><span>Kërkesë Biznesi</span></a>
                    </li>
                </div>
            </div>

            <div className="fixedNav" style={{ backgroundColor: currentBg !== "#FFFFFF" ? "#FFFFFF" : "#42295E" }}>
                <li><a onClick={() => setActiveLink(1)}  style={{ backgroundColor: currentBg !== "#FFFFFF" ? activeLink === 1 ? "#42295E" : "#FFFFFF" : activeLink === 1 ? "#FFFFFF" : "#42295E" }} href="#ballina" className='active'><img src={currentBg === "#FFFFFF" ? activeLink === 1 ? HomeDark : HomeWhite : activeLink === 1 ? HomeWhite : HomeDark } alt="Home"/></a></li>
                <li><a onClick={() => setActiveLink(2)} style={{ backgroundColor: currentBg !== "#FFFFFF" ? activeLink === 2 ? "#42295E" : "#FFFFFF" : activeLink === 2 ? "#FFFFFF" : "#42295E"}} href="#projekti" ><img src={currentBg === "#FFFFFF" ?  activeLink === 2 ? ProjectDark : ProjectWhite : activeLink === 2 ? ProjectWhite : ProjectDark } alt="Project"/></a></li>
                <li><a onClick={() => setActiveLink(3)} style={{ backgroundColor: currentBg !== "#FFFFFF" ? activeLink === 3 ? "#42295E" : "#FFFFFF" : activeLink === 3 ? "#FFFFFF" : "#42295E"}} href="#sherbimet"><img src={currentBg === "#FFFFFF" ? activeLink === 3 ? ServiceDark : ServiceWhite  : activeLink === 3 ? ServiceWhite : ServiceDark } alt="Project"/></a></li>
                <li><a onClick={contactRoute} style={{ backgroundColor: currentBg !== "#FFFFFF" ? activeLink === 4 ? "#42295E" : "#FFFFFF" : activeLink === 4 ? "#FFFFFF" : "#42295E"}} href="#kontakti"><img src={currentBg === "#FFFFFF" ? activeLink === 4 ? ContactDark : ContactWhite : activeLink === 4 ? ContactWhite : ContactDark} alt="Project"/></a></li>
            </div>
        </div>
    );
};

export default Header;