import React from 'react';
import "./index.scss"
import FooterLogo from "../../assets/logos/FooterLogo.png"
import FooterFacebook from "../../assets/logos/FooterFacebook.png"
import FooterInstagram from "../../assets/logos/FooterInstagram.png"
import FooterTwitter from "../../assets/logos/FooterTwitter.png"
import FooterAppstore from "../../assets/logos/FooterAppstore.png"
import FooterGooglePlay from "../../assets/logos/FooterGoogleplay.png"
import Copyright from "../../assets/logos/Copyright.png"
import {useNavigate} from "react-router-dom";

const Footer = () => {

    let navigate = useNavigate();
    const contactRoute = () =>{
        let path = `/contact`;
        navigate(path);
    }
    const termsRoute = () =>{
        let path = `/terms_conditions`;
        navigate(path);
    }
    return (
        <div className="footer_container">
            <div className="first_section">
                <div className="footer_title">
                    <div className="footer_logo" >
                        <img src={FooterLogo} alt="footer_logo"/>
                        <p>BERBERI</p>
                    </div>
                    <div className="footer_desc">
                        Berberi është aplikacioni më i përshtatshëm për rezervime online si dhe shërbime të ndryshme për
                        bizneset e berberave.
                    </div>
                    <div className="footer_link">
                        <a href="#"> <img src={FooterFacebook} alt="facebook"/></a>
                        <a href="#"> <img src={FooterInstagram} alt="instagram"/></a>
                        <a href="#"> <img src={FooterTwitter} alt="Gmail"/></a>
                    </div>

                    <div className="footer_btns">
                        <div className="ios">
                            <img src={FooterAppstore} alt="footer_appstore"/>
                            <a href="#">App Store</a>
                        </div>
                        <div className="android">
                            <img src={FooterGooglePlay} alt="footer_playstore"/>
                            <a href="#">Play Store</a>
                        </div>
                    </div>
                </div>
                <div className="footer_links">
                    <a href="#ballina">Ballina</a>
                    <a href="#projekti">Projekti</a>
                    <a href="#sherbimet">Shërbimet</a>
                    <a onClick={contactRoute}>Kontakti</a>
                    <a onClick={termsRoute}>Kushtet e përdorimit</a>
                    <a href="#kontakti">Kërkesë Biznesi</a>
                </div>
            </div>
            <div className="copyright">
                <img src={Copyright} alt="copyright"/>
                <p>Të gjitha të drejtat e rezervuara nga <strong>Berberi.app</strong></p>
            </div>
        </div>
    );
};

export default Footer;