import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import BusinessRequest from "./pages/contact";
import ClientContact from "./pages/contact";
import TermsAndConditions from "./pages/terms_conditions";
import ScrollToTop from "./contexts/ScrollToTop";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <ScrollToTop />
        <Routes>
            <Route path="/" element={<App />}/>
            <Route path="/contact" element={<ClientContact />} />
            <Route path="/terms_conditions" element={<TermsAndConditions />} />
        </Routes>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
