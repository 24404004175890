import React, {useEffect, useRef, useState} from "react";
import Header from "./components/Header";
import HomeBanner from "./components/HomeBanner";
import "./App.scss";
import gsap from "gsap";
import ProjectBanner from "./components/ProjectBanner";
import SText from "./components/SText";
import SText2 from "./components/SText2";
import AnimationContext from "./contexts/AnimationContext";
import {ReactLenis} from "lenis/react";
import Information from "./components/Information";
import Business from "./components/Business";
import Instructions from "./components/Instructions";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import AnimatedCursor from "react-animated-cursor";

function App() {
    const scrollRef = useRef(null);
    const [currentBg, setCurrentBg] = useState('#FFFFFF');



    useEffect(() => {
        gsap.to('body', { backgroundColor: currentBg, duration: .5 });
    }, [currentBg]);



    return (
        <ReactLenis root>
            <AnimationContext.Provider value={{currentBg, setCurrentBg}}>
                <div ref={scrollRef} className="mainApp">
                    <AnimatedCursor
                        innerStyle={{zIndex: 100000000000000}}
                        innerSize={15}
                        outerSize={15}
                        color='0, 0, 0'
                        outerAlpha={0.2}
                        innerScale={0.7}
                        outerScale={5}
                      />
                    <Header/>
                    <HomeBanner/>
                    <ProjectBanner/>
                    <SText/>
                    <SText2/>
                    <Information />
                    <Instructions/>
                    <Business />
                    <Contact />
                    <Footer />

                </div>
            </AnimationContext.Provider>
        </ReactLenis>

    );
}

export default App;
