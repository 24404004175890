import React, {useState} from 'react';
import "./index.scss"

const CustomInput = ({type, title, value, onChange, isForContactPage = false}) => {
    const [inputValue, setInputValue] = useState(value || '');

    const handleChange = (event) => {
        const newValue = event.target.value;

        if(type === "tel"){
            const numericValue = newValue.replace(/^\+383\s/, '');
            setInputValue(sanitizePhoneNumber(numericValue));
            if (onChange) {
                onChange(sanitizePhoneNumber(numericValue));
            }
        }else{
            setInputValue(newValue);
            if (onChange) {
                onChange(newValue);
            }
        }

        console.log(inputValue)

    };
    const sanitizePhoneNumber = (input) => {
        // Ensure the number starts with 4 and has exactly 7 digits
        let formatted = input.replace(/[^\d]/g, '');



        // Ensure it starts with '4'
        if (!formatted.startsWith('4')) {
            formatted = '4' + formatted;
        }

        // Limit to 7 digits after the prefix
        if (formatted.length > 8) {
            formatted = formatted.slice(0, 8);
        }

        // Apply formatting
        if (formatted.length > 2) {
            formatted = `${formatted.slice(0, 2)} ${formatted.slice(2)}`;
        }

        // Return the formatted number with the prefix
        return `+383 ${formatted}`;
    };

    return (
        <div className="custom_input_container">
            <p className="custom_input_title">{title}</p>
            <input
                className="custom_input"
                type={type}
                value={inputValue}
                placeholder={type === 'tel' ? "+383" : null}
                style={{height: isForContactPage ? "4rem": "3rem", marginBottom: isForContactPage ? "2rem": "1rem"}}
                onChange={handleChange}
            />

            {/*<input className="custom_input" type={type}/>*/}
        </div>
    );
};

export default CustomInput;