import React, {useEffect} from 'react';
import "./index.scss"
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import SplitType from 'split-type';
import {ArrowDown2} from "iconsax-react";
import Instructions from "../Instructions";
import Business from "../Business";


gsap.registerPlugin(ScrollTrigger);
const Information = () => {


    useEffect(() => {
        const splitTypes = document.querySelectorAll('.reveal-type');

        splitTypes.forEach((char, i) => {
            const bg = char.dataset.bgColor;
            const fg = char.dataset.fgColor;

            const text = new SplitType(char, { types: 'words' });

            gsap.fromTo(
                text.words,
                {
                    color: bg,
                },
                {
                    color: fg,
                    duration: 0.3,
                    stagger: 0.02,
                    scrollTrigger: {
                        trigger: char,
                        start: 'top 70%',
                        end: 'top 20%',
                        scrub: 2,
                        markers: false,
                        toggleActions: 'play play reverse reverse',
                    },
                }
            );
        });

    }, []);


    return (
        <div className="information" id="information">
            <div className="app_descritpion">
                <p>Çfarë është aplikacioni i berberit?</p>
                <h3 className="reveal-type" data-bg-color="#F5F5F508" style={{textAlign: "center"}} data-fg-color="#FFFFFF" >Aplikacioni i berberit është program për menaxhimin e rezervimeve online. Planifikoni rezervimet tuaja me lehtësi për një përvojë të përsosur në çdo kohë.</h3>
            </div>
            <div className="scrollBtn">
                <p>Vazhdo më poshtë</p>
                <p><ArrowDown2 size={25}/></p>
            </div>
        </div>
    );
};

export default Information;