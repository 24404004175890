import React from 'react';
import "./index.scss"
import image from "../../assets/images/Register.png"
import image2 from "../../assets/images/NumberVerifyBg.jpeg"
import image3 from "../../assets/images/List.png"
import image4 from "../../assets/images/Appoint.png"

const Panel = ({ index, title, description }) => {
    const imageSrc = (panelIndex) => {
        switch (index){
            case 1:
                return image;
            case 2:
                return image2;
            case 3:
                return image3;
            case 4:
                return image4;
        }
    }
    return (
        <div className="panel_page">
            <div className="panel_image_container">
                <div className="panel_image">
                    <img src={imageSrc(index)} alt="panel_image"/>
                </div>

            </div>
            <div className="panel_info">
                <h1>{index}.</h1>
                <h5>{title}</h5>
                <p>{description}</p>
            </div>

        </div>
    );
};

export default Panel;